import { ClipboardUtils } from 'ts/commons/ClipboardUtils';
import { tsdom } from 'ts/commons/tsdom';

type ClipboardButtonProps = JSX.IntrinsicElements['button'] & { clipboardText?: string; clipboardTarget?: string };

export function ClipboardButton({ clipboardText, clipboardTarget, ...props }: ClipboardButtonProps): JSX.Element {
	return (
		<button
			{...props}
			onClick={event => {
				const targetSelector = clipboardTarget;
				if (targetSelector != null) {
					const value = tsdom.getValue(document.querySelector(targetSelector)!);
					void ClipboardUtils.copyTextToClipboard(value);
				} else {
					void ClipboardUtils.copyTextToClipboard(clipboardText!);
				}
				props.onClick?.(event);
			}}
		/>
	);
}
